/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import Cookies from 'js-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import './src/styles/app.scss';

export function onClientEntry() {
  let script = document.querySelector(`script[src="https://secure.leadforensics.com/js/131444.js"]`);

  if (Cookies.get("lead-forensics") && (!script || !img)) {
    let noscript = document.createElement("noscript");
    let img = document.createElement('img')
    img.src = "https://secure.leadforensics.com/131444.png"
    img.style = "display:none;"
    noscript.appendChild(img)
    document.body.insertBefore(noscript, document.body.firstChild)
    script = document.createElement("script");
    script.src = 'https://secure.leadforensics.com/js/131444.js';
    document.body.insertBefore(script, document.body.firstChild)
  }
}

export function onRouteUpdate() {
  let script = document.querySelector(`script[src="https://secure.leadforensics.com/js/131444.js"]`);

  if (Cookies.get("lead-forensics") && !script) {
   
    let noscript = document.createElement("noscript");
    let img = document.createElement('img')
    img.src = "https://secure.leadforensics.com/131444.png"
    img.style = "display:none;"
    noscript.appendChild(img)
    document.body.insertBefore(noscript, document.body.firstChild)
    script = document.createElement("script");
    script.src = 'https://secure.leadforensics.com/js/131444.js';
    document.body.insertBefore(script, document.body.firstChild)
  }
}
