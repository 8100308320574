import { Link } from 'gatsby';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Cookies from 'js-cookie';
import getRoute from '../helpers/getRoute';

const optionsArr = [
  { title: '', info: '', name_id: '' },
  { title: '', info: '', name_id: '' },
  { title: '', info: '', name_id: '' },
];

function CookieBanner({
  cookieBanner = {
    title: '',
    cookie_description: '',
    accept_all_button: '',
    accept_selected_button: '',
    accept_necessary_button: '',
    options: optionsArr,
    privacy_policy_button: '',
  },
  lang,
}) {
  const {
    title, cookie_description, accept_all_button,
    accept_selected_button, accept_necessary_button, options,
    privacy_policy_button,
  } = cookieBanner;
  const [selected, setSelected] = React.useState([]);
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const initialState = options ? options.map((item) => ({ ...item, value: item.name_id === 'necessary' })) : [];
    setSelected(initialState);
  }, [options]);

  React.useEffect(() => {
    setShow(!Cookies.get('hideBanner'));
  }, []);

  const handleClick = (e) => {
    if (e.target.id === 'necessary') return;
    const newState = [].concat(selected);
    const updateItem = newState.find((item) => item.name_id === e.target.id);
    updateItem.value = !updateItem.value;
    setSelected(newState);
  };

  const marketingCookies = () => {
    Cookies.set('gatsby-gdpr-google-analytics', true, { expires: Infinity });
    Cookies.set('gatsby-gdpr-google-tagmanager', true, { expires: Infinity });
    Cookies.set('gatsby-gdpr-facebook-pixel', true, { expires: Infinity });
    Cookies.set('lead-forensics', true, { expires: Infinity });
  };

  const handleSelected = () => {
    if (selected.find((item) => item.name_id === 'marketing').value) {
      marketingCookies();
    }
    setShow(false);
    Cookies.set('hideBanner', true, { expires: Infinity });
  };

  const handleAcceptAll = () => {
    marketingCookies();
    setShow(false);
    Cookies.set('hideBanner', true, { expires: Infinity });
  };
  const handleNecessary = () => {
    setShow(false);
    Cookies.set('hideBanner', true, { expires: Infinity });
  };
  return (
    show ? (
      <div className="cookie-banner-wrapper">
        <h2 className="cookie-title">{title}</h2>
        <p className="cookie-dsc-text">
          {cookie_description}
        </p>
        <Link to={getRoute(lang, '/datenschutz')} className="policy-link">{privacy_policy_button}</Link>
        <div className="options">
          {
            selected ? selected.map((item) => {
              const { info } = item;
              return (
                <OverlayTrigger 
                  placement="top"
                  key={item.name_id}
                  overlay={(
                    <Tooltip className="cookie-tooltip" id={item.title}>
                      {info}
                    </Tooltip>
                )}
                >
                  <div>
                    <label className="container">
                      <input type="checkbox" id={item.name_id} checked={item.value} onChange={handleClick} />
                      <span className="checkmark" />
                      <span className="text">
                        {item.title}
                      </span>
                    </label>
                  </div>
                </OverlayTrigger>
              );
            }) : null
          }
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleAcceptAll}
        >
          {accept_all_button}

        </button>
        <div className="accept-options">
          <button type="button" onClick={handleNecessary}>{accept_necessary_button}</button>
          <button type="button" onClick={handleSelected}>
            {accept_selected_button}
          </button>
        </div>
      </div>
    ) : null
  );
}

export default CookieBanner;
