/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from "react";
import PropTypes from "prop-types";
import { ParallaxProvider } from "react-scroll-parallax";
import { motion, AnimatePresence } from "framer-motion";
import scrollTo from "gatsby-plugin-smoothscroll";
import Header from "./header";
import Hero from "./hero";
import SEO from "./seo";
import CookieBanner from "./CookieBanner";
import FooterComponent from "./footer";
// const homapageRoutes = ['', '/', '/en', '/en/'];

const Layout = ({ children, pageContext, data, location }) => {
  const template = data?.wordpressPage?.template;
  const path = data?.wordpressPage?.path;
  const pageTitle = data?.wordpressPage?.title;
  const yoastHead = data?.wordpressPage?.yoast_head;
  const lang = pageContext?.lang;
  const options = data?.wordpressAcfOptions?.options;
  const cookieBanner = pageContext?.cookieBanner;

  const typePost = data?.wordpressPost?.type;
  let yoastHeadPost = data?.wordpressPost?.yoast_head;

  if(yoastHeadPost){
    const originalItemBlogUrl=`https://helmut.de/${pageContext.slug}`
    let newBlogItemUrl=`https://helmut.de/blog/${pageContext.slug}`

    if(lang == "en_US"){
      newBlogItemUrl=`https://helmut.de/en/blog/${pageContext.slug}`
    }

    const regExpURL = new RegExp(originalItemBlogUrl,"g");
    const regExpItemType = new RegExp("itemtype","g");

    yoastHeadPost = yoastHeadPost.replace(regExpURL,newBlogItemUrl)
    yoastHeadPost = yoastHeadPost.replace(regExpItemType,"itemType")
  }  
  const pageTitlePost = data?.wordpressPost?.title;

  const [toTop, setToTop] = React.useState(false);
  React.useEffect(() => {
    if (typeof window === "undefined") return () => {};
    const getScroll = () => {
      if (document.documentElement.scrollTop > 1000) {
        setToTop(true);
      }
      if (document.documentElement.scrollTop < 1000) {
        setToTop(false);
      }
    };
    window.addEventListener("scroll", getScroll);
    return () => window.removeEventListener("scroll", getScroll);
  }, []);

  return (
    <div className="layout">
      <CookieBanner cookieBanner={cookieBanner} lang={lang} />
      <Header
        siteTitle="helmut"
        lang={lang}
        location={typeof location !== "undefined" && location}
        options={options}
      />
      <SEO
        yoastHead={typePost === "post" ? yoastHeadPost : yoastHead}
        title={typePost === "post" ? pageTitlePost : pageTitle}
        lang={lang}
      />
      {template === "homepage-template.php" ||
      template === "helmuttools-template.php" ? (
        <Hero
          lang={lang}
          color={data.wordpressPage.acf.color_template || "#fff"}
          path={path}
        />
      ) : null}
      <ParallaxProvider>
        <main>{children}</main>
        <FooterComponent lang={lang} />
      </ParallaxProvider>
      <AnimatePresence>
        {toTop && (
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            className="scroll-up-warpper"
            onClick={() => scrollTo("body")}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#fff"
                d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z"
              />
            </svg>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

Layout.defaultProps = {
  transitionProps: {
    uri: "",
  },
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  transitionProps: PropTypes.shape({}),
};
  
export default Layout;
