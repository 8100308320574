// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-blog-post-template-jsx": () => import("./../../../src/templates/BlogPostTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-post-template-jsx" */),
  "component---src-templates-blog-template-jsx": () => import("./../../../src/templates/BlogTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-template-jsx" */),
  "component---src-templates-case-study-template-jsx": () => import("./../../../src/templates/CaseStudyTemplate.jsx" /* webpackChunkName: "component---src-templates-case-study-template-jsx" */),
  "component---src-templates-changelog-template-jsx": () => import("./../../../src/templates/ChangelogTemplate.jsx" /* webpackChunkName: "component---src-templates-changelog-template-jsx" */),
  "component---src-templates-datenschutz-template-jsx": () => import("./../../../src/templates/DatenschutzTemplate.jsx" /* webpackChunkName: "component---src-templates-datenschutz-template-jsx" */),
  "component---src-templates-faq-template-jsx": () => import("./../../../src/templates/FAQTemplate.jsx" /* webpackChunkName: "component---src-templates-faq-template-jsx" */),
  "component---src-templates-homepage-template-jsx": () => import("./../../../src/templates/HomepageTemplate.jsx" /* webpackChunkName: "component---src-templates-homepage-template-jsx" */),
  "component---src-templates-impressum-template-jsx": () => import("./../../../src/templates/ImpressumTemplate.jsx" /* webpackChunkName: "component---src-templates-impressum-template-jsx" */),
  "component---src-templates-kontakt-template-jsx": () => import("./../../../src/templates/KontaktTemplate.jsx" /* webpackChunkName: "component---src-templates-kontakt-template-jsx" */),
  "component---src-templates-media-center-template-jsx": () => import("./../../../src/templates/MediaCenterTemplate.jsx" /* webpackChunkName: "component---src-templates-media-center-template-jsx" */),
  "component---src-templates-product-template-jsx": () => import("./../../../src/templates/ProductTemplate.jsx" /* webpackChunkName: "component---src-templates-product-template-jsx" */),
  "component---src-templates-referenzen-template-jsx": () => import("./../../../src/templates/ReferenzenTemplate.jsx" /* webpackChunkName: "component---src-templates-referenzen-template-jsx" */),
  "component---template-cache-tmp-please-do-not-delete-jsx": () => import("./../../../.template-cache/tmp-please-do-not-delete.jsx" /* webpackChunkName: "component---template-cache-tmp-please-do-not-delete-jsx" */)
}

