/* eslint-disable no-nested-ternary */
import { Link } from 'gatsby';
import React, { useState } from 'react';
import formatUrl from '../helpers/formatUrl';
import getRoute from '../helpers/getRoute';
import Image from './image';
import ArrowDown from '../../static/assets/down-arrow.svg';

function NavItem({ item, lang }) {
  const [dropdown, setDropdown] = useState(false);
  const isHomepageLink = item.url.includes('/homepage/');
  const notLink = item.url === '#';

  const handleNotLink = () => {
    if (!notLink) return;
    setDropdown(!dropdown);
  };
  
  const isOuterLink = () => {
    try {
      const url = new URL(item.url);
      const isRedirect =  url.searchParams.get('route-as');
      return (isRedirect === 'url');
    } catch (e) {
      return false;
    }
  }
  
  const getOuterLink = () => {
    try {
      const url = new URL(item.url);
      return `${url.origin}${url.pathname}`;
    }catch (e) {
      return null;
    }
  }
  
  const isOuterLinkNewTab = () => {
    try {
      const url = new URL(item.url);
      const isNewTab =  url.searchParams.get('new-tab');
      return (Number.parseInt(isNewTab) === 1);
    } catch (e) {
      return false;
    }
  }
  
  return (
    <li
      className={`${isHomepageLink ? 'logo-li' : 'nav-item'} ${dropdown ? 'active' : ''}`}
      onClick={handleNotLink}
    >
      {isOuterLink() && (
        <a href={getOuterLink()} className="nav-item-link" itemProp="url" target={`${isOuterLinkNewTab() ? '_blank' : '_self'}`}>
          <span itemProp="name">{item.title}</span>
        </a>
      )}
      {!isOuterLink() && (
        <>
          <Link
            to={isHomepageLink ? getRoute(lang, '/') : notLink ? null : getRoute(lang, formatUrl(item.url))}
            className="nav-item-link"
            itemProp="url"
          >
            {isHomepageLink ? <Image /> : <span itemProp="name">{item.title}</span>}
            { item.wordpress_children ? (
                <div className={`arrow  ${dropdown ? 'active' : ''}`}>
                  <ArrowDown />
                </div>
              )
              : null}
          </Link>
          <div className="border-gradient" />
          {item.wordpress_children
            ? (
              <div className="dropdown">
                {item.wordpress_children.map((link) => (
                  <Link
                    key={link.url}
                    className="dropdown-link"
                    to={`${getRoute(lang, formatUrl(link.url))}/`}
                    activeClassName={`active-dropdown-link ${link.object_slug}`}
                    itemProp="url"
                  >
                    <span itemProp="name">{link.title}</span>
                  </Link>
                ))}
              </div>
            )
            : null}
        </>
      )}
    </li>
  );
}

export default NavItem;
