/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import d from 'gatsby-plugin-smoothscroll';
import HelmutLogo from '../../static/assets/HelmutLogo.svg';
import HelmutFXsvg from '../../static/assets/HelmutFX.svg';
import HelmutHKlogo from '../../static/assets/HelmutHK.svg';
import HelmutCOlogo from '../../static/assets/HelmutCO.svg';
import HelmutIOlogo from '../../static/assets/HelmutIO.svg';
// import { Context } from './context';
import getRoute from '../helpers/getRoute';
import BigGrid from '../../static/assets/Grid-BIG.svg';
import AllGrid from '../../static/assets/Grid-HAll.svg';
import CoGrid from '../../static/assets/Grid-Hco.svg';
import FxGrid from '../../static/assets/Grid-Hfx.svg';
import HkGrid from '../../static/assets/Grid-Hhk.svg';
import IoGrid from '../../static/assets/Grid-Hio.svg';
import NameGrid from '../../static/assets/Grid-HName.svg';

const productsTitles = [
  { title: 'Helmut4', subtitle: 'Empowers editing projects' },
  { title: 'HelmutFX', subtitle: 'Project manager' },
  { title: 'HelmutIO', subtitle: 'Renderfarm' },
  { title: 'HelmutCO', subtitle: 'visualising database' },
  { title: 'HelmutHK', subtitle: 'housekeeper' },
];

const Hero = ({ color, lang, path }) => {
  const [width, setWidth] = React.useState(
    typeof window !== 'undefined' ? window.innerWidth : 0,
  );
  useEffect(() => {
    if (typeof window === 'undefined') return () => {};
    function setWidthFuc() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', setWidthFuc);
    return () => window.removeEventListener('resize', setWidthFuc);
  }, []);
  const helMainMount = path.includes('/homepage');
  const helFxMount = path.includes('/helmut-fx');
  const helHkMount = path.includes('/helmut-hk');
  const helCoMount = path.includes('/helmut-co');
  const helIoMount = path.includes('/helmut-io');
  const logoVariants = {
    mainPassive: { scale: 0.4, translateX: '-48.5%', translateY: '-96.5%' },
    fxPassive: { scale: 0.4, translateX: '-95%' },
    hkPassive: { scale: 0.4, translateX: '95%' },
    CoPassive: { scale: 0.4, translateX: '48.5%', translateY: '96.5%' },
    IoPassive: { scale: 0.4, translateX: '48.5%', translateY: '-96.5%' },
    prodActive: {
      scale: 1,
      translateX: 0,
      translateY: 0,
      zIndex: 1,
    },
    mainActive: {
      scale: 1,
      translateX: 0,
      translateY: 0,
      zIndex: 1,
    },
  };

  const textVariants = {
    main: {
      translateY: width <= 536 ? '240%' : '275%',
      translateX: '-95%',
      rotate: 0,
    },
    fx: {
      translateY: 0,
      translateX: width <= 536 ? '-140%' : '-155%',
      rotate: width <= 536 ? -90 : 0,
    },
    io: { translateY: '-265%', translateX: '110%', rotate: 0 },
    co: { translateY: '275%', translateX: '95%', rotate: 0 },
    hk: {
      translateY: 0,
      translateX: width <= 536 ? '135%' : '150%',
      rotate: width <= 536 ? 90 : 0,
    },
  };

  const bigGridVariants = {
    active: { scale: 2.2, opacity: 1 },
    passive: { scale: 2.0 },
    passiveOpa: { opacity: 0, transition: { duration: 0.1 } },
  };

  const smallGridVariants = {
    all: { translateX: '-83%', translateY: '-155%', opacity: 1 },
    fx: { scale: 0.76, translateX: '-135%', opacity: 1 },
    io: { translateX: '83%', translateY: '-155%', opacity: 1 },
    co: { translateY: '155%', translateX: '83%', opacity: 1 },
    hk: { scale: 0.76, translateX: '135%', opacity: 1 },
    name: { translateX: '-83%', translateY: '155%' },
    passive: { translateX: 0, translateY: 0 },
    passiveOpa: { opacity: 0, transition: { duration: 0.1 } },
  };

  return (
    <div className="hero">
      <div className="logo-container">
        <motion.div
          initial={{ scale: 0.4 }}
          animate={
            helFxMount || helHkMount || helCoMount || helIoMount
              ? 'mainPassive'
              : 'mainActive'
          }
          transition={{ type: 'spring', duration: 0.4, damping: 25 }}
          variants={logoVariants}
          className="logo-item-warpper main"
        >
          <Link to={getRoute(lang, '/')}>
            <HelmutLogo />
          </Link>
        </motion.div>
        <motion.div
          animate={helFxMount ? 'prodActive' : 'fxPassive'}
          transition={{ type: 'spring', duration: 0.4, damping: 25 }}
          variants={logoVariants}
          initial={{ scale: 0.4 }}
          className="logo-item-warpper"
        >
          <Link to={getRoute(lang, '/helmut-fx')}>
            <HelmutFXsvg />
          </Link>
        </motion.div>
        <motion.div
          animate={helHkMount ? 'prodActive' : 'hkPassive'}
          transition={{ type: 'spring', duration: 0.4, damping: 25 }}
          initial={{ scale: 0.4 }}
          variants={logoVariants}
          className="logo-item-warpper"
        >
          <Link to={getRoute(lang, '/helmut-hk')}>
            <HelmutHKlogo />
          </Link>
        </motion.div>
        <motion.div
          className="logo-item-warpper"
          animate={helCoMount ? 'prodActive' : 'CoPassive'}
          transition={{ type: 'spring', duration: 0.4, damping: 25 }}
          initial={{ scale: 0.4 }}
          variants={logoVariants}
        >
          <Link to={getRoute(lang, '/helmut-co')}>
            <HelmutCOlogo />
          </Link>
        </motion.div>
        <motion.div
          animate={helIoMount ? 'prodActive' : 'IoPassive'}
          transition={{ type: 'spring', duration: 0.4, damping: 25 }}
          initial={{ scale: 0.4 }}
          variants={logoVariants}
          className="logo-item-warpper"
        >
          <Link to={getRoute(lang, '/helmut-io')}>
            <HelmutIOlogo />
          </Link>
        </motion.div>
        <motion.div
          className={`hero-text-warpper ${
            helHkMount || helCoMount || helIoMount
              ? 'reverse'
              : helMainMount
                ? 'main'
                : ''
          }`}
          animate={
            helFxMount
              ? 'fx'
              : helIoMount
                ? 'io'
                : helCoMount
                  ? 'co'
                  : helHkMount
                    ? 'hk'
                    : 'main'
          }
          transition={{ type: 'spring', duration: 0.4, damping: 25 }}
          variants={textVariants}
        >
          <h1>
            {helFxMount
              ? productsTitles[1].title
              : helIoMount
                ? productsTitles[2].title
                : helCoMount
                  ? productsTitles[3].title
                  : helHkMount
                    ? productsTitles[4].title
                    : productsTitles[0].title}
          </h1>
          <h1 className="title">
            {helFxMount
              ? productsTitles[1].subtitle
              : helIoMount
                ? productsTitles[2].subtitle
                : helCoMount
                  ? productsTitles[3].subtitle
                  : helHkMount
                    ? productsTitles[4].subtitle
                    : productsTitles[0].subtitle}
          </h1>
        </motion.div>
        <motion.div
          animate="active"
          variants={bigGridVariants}
          className="grid-item big-grid"
        >
          <BigGrid />
        </motion.div>
        <motion.div
          variants={smallGridVariants}
          animate="fx"
          className="grid-item h-fx-grid"
        >
          <FxGrid />
        </motion.div>
        <motion.div
          variants={smallGridVariants}
          animate="all"
          className="grid-item h-all-grid"
        >
          <AllGrid />
        </motion.div>
        <motion.div
          variants={smallGridVariants}
          animate="co"
          className="grid-item h-co-grid"
        >
          <CoGrid />
        </motion.div>
        <motion.div
          variants={smallGridVariants}
          animate="hk"
          className="grid-item h-hk-grid"
        >
          <HkGrid />
        </motion.div>
        <motion.div
          variants={smallGridVariants}
          animate="io"
          className="grid-item h-io-grid"
        >
          <IoGrid />
        </motion.div>
        <motion.div
          variants={smallGridVariants}
          animate="name"
          className="grid-item h-name-grid"
        >
          <NameGrid />
        </motion.div>
      </div>
      <div
        className="down-arrow"
        onClick={() => d('.bg-shadow-article')}
      >
        <div className="box">
          <span />
          <span />
          <span style={{ borderColor: color }} />
        </div>
      </div>
    </div>
  );
};

export default Hero;
