import React, { useState, useEffect } from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import NavItem from "./navItem";

const Header = ({ location, lang }) => {
  const [menu, setMenu] = useState(false);
  const { menuDe, menuEn } = useStaticQuery(graphql`
    query {
      menuDe: wordpressWpApiMenusMenusItems(name: { eq: "Main menu DE" }) {
        items {
          object_id
          title
          url
          wordpress_children {
            object_id
            title
            url
            object_slug
          }
        }
      }
      menuEn: wordpressWpApiMenusMenusItems(name: { eq: "Main menu EN" }) {
        items {
          object_id
          title
          url
          wordpress_children {
            object_id
            title
            url
            object_slug
          }
        }
      }
    }
  `);
  const menuItems = lang === "de_DE" ? menuDe.items : menuEn.items;

  const route = typeof window !== "undefined" ? location.pathname : "";
  useEffect(() => {
    setMenu(false);
    // setDropdown(false);
  }, [location]);

  const handleClickBars = () => {
    setMenu(!menu);
  };
  const handleLangSwitch = () => {
    if (route.startsWith("/en")) {
      const routeTo = route.split("/").slice(2).join("/");
      navigate(`/${routeTo.startsWith("blog") ? "blog" : routeTo}`);
    } else {
      navigate(`/en${route.startsWith("/blog") ? "/blog" : route}`);
    }
  };
  return (
    <header>
      <nav
        className={`navbar-warpper ${menu && "active"}`}
        role="navigation"
        itemScope
        itemType="http://schema.org/SiteNavigationElement"
      >
        <ul className={`navbar-list ${menu && "active"}`}>
          {menuItems.map(item => (
            <NavItem key={item.object_id} item={item} lang={lang} />
          ))}
        </ul>
        <div className="mobile-nav">
          <div className="bars">
            <button
              onClick={handleClickBars}
              className={`hamburger--squeeze ${menu ? "is-active" : ""}`}
              type="button"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </div>
          <span className="lang-change">
            <span className="sprache">
              {lang === "de_DE" ? "sprache" : "language"}
            </span>
            {": "}
            <button
              onClick={handleLangSwitch}
              onKeyDown={handleLangSwitch}
              type="button"
              disabled={lang === "de_DE"}
            >
              DE
            </button>{" "}
            <span>/</span>{" "}
            <button
              onClick={handleLangSwitch}
              onKeyDown={handleLangSwitch}
              type="button"
              disabled={lang === "en_US"}
            >
              EN
            </button>
          </span>
        </div>
      </nav>
    </header>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
