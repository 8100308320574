/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
// import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import getRoute from '../helpers/getRoute';
import emailImage from '../images/mailPng.png';
import mainIcon from '../images/helmut-logo-farbverlauf-smaller-h.png';
import tel from '../images/tel.png';
import mobileTel from '../images/Icon_Phone_mobile.png';
import mobileMail from '../images/Icon_Mail_mobile.png';
import mobile from '../images/Logo_Footer_mobile.png';
import formatUrl from '../helpers/formatUrl';
import Facebook from '../../static/assets/facebook.svg';
import Instagram from '../../static/assets/instagram.svg';
import Linkedin from '../../static/assets/linkedin.svg';

const FooterComponent = ({ lang }) => {
  const { wordpressAcfOptions, menuDe, menuEn } = useStaticQuery(
    graphql`
      query {
        wordpressAcfOptions {
        options {
          kontakt {
            de_label
            en_label
          }
          produkte {
            de_label
            en_label
          }
          referenzen {
            de_label
            en_label
          }
          sprache {
            de_label
            en_label
          }
          copyright_text
          contact_icons_footer {
            email
            phone
          }
          bottom_footer_right {
            name
            english_name
            url
          }
          bottom_footer_left {
            name
            english_name
            url
          }
        social_media_links {
          links {
            link {
              title
              url
              target
            }
          }
        }
        }
      }
      menuDe: wordpressWpApiMenusMenusItems(name: {eq: "Footer menu DE"}) {
          items {
            object_id
            title
            url
            wordpress_children {
              object_id
              title
              url
              object_slug
          }
        }
      }
      menuEn: wordpressWpApiMenusMenusItems(name: {eq: "Footer menu EN"}) {
          items {
            object_id
            title
            url
            wordpress_children {
              object_id
              title
              url
              object_slug
          }
        }
      }
    }
  `,
  );
  const { links: socialMediaLinks } = wordpressAcfOptions.options.social_media_links;
  const footerMenu = lang === 'de_DE' ? menuDe.items : menuEn.items;
  return (
    <footer>
      <div className="footer-container">
        <div className="email-tel-desktop">
          <a
            href={`mailto:${wordpressAcfOptions.options.contact_icons_footer.email}`}
          >
            <img
              className="email"
              src={emailImage}
              style={{ marginRight: '-35px', width: '94px', height: '82px' }}
              alt="emailImage"
            />
          </a>
          <svg
            width="94px"
            height="151px"
            viewBox="0 0 141 227"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'relative', zIndex: '2' }}
          >
            <title>Grid_Mail_desktop</title>

            <g
              id="Helmut4"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="00-HelmutTools_Home-START"
                transform="translate(-503.000000, -12147.000000)"
              >
                2/3*
                <g id="Footer" transform="translate(82.000000, 12127.000000)">
                  <g id="Group-60" transform="translate(331.000000, 0.000000)">
                    <g
                      id="Grid_Mail_desktop"
                      transform="translate(91.000000, 21.000000)"
                    >
                      <ellipse
                        id="Oval"
                        fill="#FFFFFF"
                        transform="translate(75.299045, 111.599300) rotate(-60.000000) translate(-75.299045, -111.599300) "
                        cx="75.2990445"
                        cy="111.5993"
                        rx="9.18775788"
                        ry="9.19592114"
                      /> 
                      <ellipse
                        id="Oval"
                        stroke="#FFFFFF"
                        transform="translate(20.587964, 20.683300) rotate(-60.000000) translate(-20.587964, -20.683300) "
                        cx="20.587964"
                        cy="20.6832997"
                        rx="14.7004126"
                        ry="14.7134738"
                      />
                      <ellipse
                        id="Oval"
                        stroke="#FFFFFF"
                        transform="translate(22.735788, 204.316700) rotate(-60.000000) translate(-22.735788, -204.316700) "
                        cx="22.7357879"
                        cy="204.3167"
                        rx="14.7004126"
                        ry="14.7134738"
                      />
                      <g
                        id="Oval-2"
                        transform="translate(114.101806, 110.632664) rotate(-60.000000) translate(-114.101806, -110.632664) translate(96.621806, 93.183685)"
                        fill="#000000"
                        stroke="#FFFFFF"
                        opacity="1"
                      >
                        <ellipse
                          id="Oval"
                          cx="17.48"
                          cy="17.4489796"
                          rx="17.48"
                          ry="17.4489796"
                        />
                      </g>
                      <ellipse
                        id="Oval"
                        fill="#FFFFFF"
                        transform="translate(114.101806, 110.632664) rotate(-60.000000) translate(-114.101806, -110.632664) "
                        cx="114.101806"
                        cy="110.632664"
                        rx="9.18775788"
                        ry="9.19592114"
                      />
                      <line
                        x1="71.1773623"
                        y1="32.8110527"
                        x2="28.9684782"
                        y2="104.0469"
                        id="Path-2"
                        stroke="#FFFFFF"
                        transform="translate(50.072920, 68.428976) rotate(-60.000000) translate(-50.072920, -68.428976) "
                      />
                      <line
                        x1="93.4657835"
                        y1="154.610547"
                        x2="8.47904885"
                        y2="154.545153"
                        id="Path-3"
                        stroke="#FFFFFF"
                        transform="translate(50.972416, 154.577850) rotate(-60.000000) translate(-50.972416, -154.577850) "
                      />
                      <path
                        d="M85.5554502,106.293703 C85.5554502,106.293703 88.3153342,109.820458 93.8351022,116.873966 C88.3153342,109.820458 85.5554502,106.293703 85.5554502,106.293703 Z"
                        id="Path-4"
                        stroke="#FFFFFF"
                        transform="translate(89.695276, 111.583835) rotate(-53.000000) translate(-89.695276, -111.583835) "
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <svg
            width="140px"
            height="121px"
            viewBox="0 0 210 182"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginLeft: '-20px' }}
          >
            <title>Polygon_Logo_Helmut_desktop</title>
            <g
              id="Helmut4"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="00-HelmutTools_Home-START"
                transform="translate(-619.000000, -12172.000000)"
                stroke="#FFFFFF"
              >
                <g id="Footer" transform="translate(82.000000, 12127.000000)">
                  <g id="Group-60" transform="translate(331.000000, 0.000000)">
                    <polygon
                      id="Polygon_Logo_Helmut_desktop"
                      transform="translate(311.048733, 135.979320) rotate(-30.000000) translate(-311.048733, -135.979320) "
                      points="311.048732 32 401.097465 83.9896599 401.097465 187.96898 311.048733 239.95864 221 187.96898 221 83.9896599"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <Link to={getRoute(lang, '/')}>
            <img
              src={mainIcon}
              className="helmut-tool-small-icon"
              alt="helmut-tool-small-icon"
              style={{ width: '106px', height: '92px' }}
            />
          </Link>
          <svg
            width="94px"
            height="151px"
            viewBox="0 0 140 227"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginLeft: '-20px' }}
          >
            <title>Grid_Phone_desktop</title>
            <g
              id="Helmut4"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="00-HelmutTools_Home-START"
                transform="translate(-804.000000, -12147.000000)"
              >
                <g id="Footer" transform="translate(82.000000, 12127.000000)">
                  <g id="Group-60" transform="translate(331.000000, 0.000000)">
                    <g
                      id="Grid_Phone_desktop"
                      transform="translate(393.000000, 21.000000)"
                    >
                      <ellipse
                        id="Oval"
                        fill="#FFFFFF"
                        transform="translate(62.246601, 111.599300) scale(-1, 1) rotate(-60.000000) translate(-62.246601, -111.599300) "
                        cx="62.2466007"
                        cy="111.5993"
                        rx="9.1711149"
                        ry="9.14594491"
                      />
                      <ellipse
                        id="Oval"
                        stroke="#FFFFFF"
                        transform="translate(116.561224, 20.683300) scale(-1, 1) rotate(-60.000000) translate(-116.561224, -20.683300) "
                        cx="116.561224"
                        cy="20.6832997"
                        rx="14.6737838"
                        ry="14.6335119"
                      />
                      <ellipse
                        id="Oval"
                        stroke="#FFFFFF"
                        transform="translate(114.428964, 204.316700) scale(-1, 1) rotate(-60.000000) translate(-114.428964, -204.316700) "
                        cx="114.428964"
                        cy="204.3167"
                        rx="14.6737838"
                        ry="14.6335119"
                      />
                      <g
                        id="Oval-2"
                        transform="translate(23.725019, 110.632664) scale(-1, 1) rotate(-60.000000) translate(-23.725019, -110.632664) translate(6.371685, 93.183685)"
                        fill="#000000"
                        stroke="#FFFFFF"
                      >
                        <ellipse
                          id="Oval"
                          cx="17.3533333"
                          cy="17.4489796"
                          rx="17.3533333"
                          ry="17.4489796"
                        />
                      </g>
                      <ellipse
                        id="Oval"
                        fill="#FFFFFF"
                        transform="translate(23.725019, 110.632664) scale(-1, 1) rotate(-60.000000) translate(-23.725019, -110.632664) "
                        cx="23.7250188"
                        cy="110.632664"
                        rx="9.1711149"
                        ry="9.14594491"
                      />
                      <line
                        x1="108.467897"
                        y1="32.9384074"
                        x2="66.1119567"
                        y2="103.919545"
                        id="Path-2"
                        stroke="#FFFFFF"
                        transform="translate(87.289927, 68.428976) scale(-1, 1) rotate(-60.000000) translate(-87.289927, -68.428976) "
                      />
                      <line
                        x1="128.813233"
                        y1="154.477035"
                        x2="43.9806651"
                        y2="154.678665"
                        id="Path-3"
                        stroke="#FFFFFF"
                        transform="translate(86.396949, 154.577850) scale(-1, 1) rotate(-60.000000) translate(-86.396949, -154.577850) "
                      />
                      <path
                        d="M43.8441531,106.332572 C43.8441531,106.332572 46.5845108,109.833414 52.065226,116.835097 C46.5845108,109.833414 43.8441531,106.332572 43.8441531,106.332572 Z"
                        id="Path-4"
                        stroke="#FFFFFF"
                        transform="translate(47.954690, 111.583835) scale(-1, 1) rotate(-53.000000) translate(-47.954690, -111.583835) "
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <a
            href={`tel:${wordpressAcfOptions.options.contact_icons_footer.phone}`}
          >
            <img
              className="tel"
              src={tel}
              alt="tel"
              style={{ marginLeft: '-35px', width: '94px', height: '82px' }}
            />
          </a>
        </div>

        <div className="email_tel_mobile">
          <div>
            <a
              href={`mailto:${wordpressAcfOptions.options.contact_icons_footer.email}`}
            >
              <img
                style={{ marginLeft: '-3px', marginBottom: '22px' }}
                className="mobile-icon-mail"
                src={mobileMail}
                alt="mobile-icon"
              />

              <svg
                style={{
                  marginLeft: '-54px',
                  marginBottom: '-18px',
                  position: 'relative',
                  zIndex: '2',
                }}
                width="70px"
                height="59px"
                viewBox="0 0 70 59"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Grid_Mail_mobile@1x</title>
                <g
                  id="Helmut4"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="00-HelmutTools_Home-START-Mobile"
                    transform="translate(-73.000000, -7981.000000)"
                  >
                    <g
                      id="Grid_Mail_mobile"
                      transform="translate(74.000000, 7982.000000)"
                    >
                      <ellipse
                        id="Oval"
                        fill="#FFFFFF"
                        cx="44.0111111"
                        cy="38.692053"
                        rx="3.21111111"
                        ry="3.20860927"
                      />
                      <ellipse
                        id="Oval"
                        stroke="#FFFFFF"
                        cx="62.7111111"
                        cy="5.28476821"
                        rx="5.28888889"
                        ry="5.28476821"
                      />
                      <ellipse
                        id="Oval"
                        stroke="#FFFFFF"
                        cx="5.28888889"
                        cy="39.2582781"
                        rx="5.28888889"
                        ry="5.28476821"
                      />
                      <g
                        id="Oval-2"
                        transform="translate(44.577778, 44.543046)"
                        fill="#2A2A2A"
                        stroke="#FFFFFF"
                      >
                        <ellipse
                          id="Oval"
                          cx="6.42222222"
                          cy="6.22847682"
                          rx="6.42222222"
                          ry="6.22847682"
                        />
                      </g>
                      <ellipse
                        id="Oval"
                        fill="#FFFFFF"
                        cx="51.1888889"
                        cy="50.7715232"
                        rx="3.21111111"
                        ry="3.20860927"
                      />
                      <line
                        x1="60.8222222"
                        y1="10.5695364"
                        x2="45.7111111"
                        y2="36.2384106"
                        id="Path-2"
                        stroke="#FFFFFF"
                      />
                      <line
                        x1="41.5555556"
                        y1="38.692053"
                        x2="10.9555556"
                        y2="38.692053"
                        id="Path-3"
                        stroke="#FFFFFF"
                      />
                      <path
                        d="M45.1143518,41.3567797 C45.1143518,41.3567797 46.1133668,42.6265356 48.1113969,45.1660474 C46.1133668,42.6265356 45.1143518,41.3567797 45.1143518,41.3567797 Z"
                        id="Path-4"
                        stroke="#FFFFFF"
                        transform="translate(46.612874, 43.261414) rotate(7.000000) translate(-46.612874, -43.261414) "
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </a>
            <a
              href={`tel:${wordpressAcfOptions.options.contact_icons_footer.phone}`}
            >
              <svg
                style={{
                  marginLeft: '30px',
                  marginBottom: '-20px',
                  position: 'relative',
                  zIndex: '2',
                }}
                width="70px"
                height="59px"
                viewBox="0 0 70 59"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Grid_Phone_mobile@1x</title>
                <g
                  id="Helmut4"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="00-HelmutTools_Home-START-Mobile"
                    transform="translate(-174.000000, -7981.000000)"
                  >
                    <g
                      id="Group-23"
                      transform="translate(74.000000, 7982.000000)"
                    >
                      <g
                        id="Grid_Phone_mobile"
                        transform="translate(135.000000, 29.000000) scale(-1, 1) translate(-135.000000, -29.000000) translate(101.000000, 0.000000)"
                      >
                        <ellipse
                          id="Oval"
                          fill="#FFFFFF"
                          cx="44.1235955"
                          cy="38.692053"
                          rx="3.24719101"
                          ry="3.20860927"
                        />
                        <ellipse
                          id="Oval"
                          stroke="#FFFFFF"
                          cx="62.6516854"
                          cy="5.28476821"
                          rx="5.34831461"
                          ry="5.28476821"
                        />
                        <ellipse
                          id="Oval"
                          stroke="#FFFFFF"
                          cx="5.34831461"
                          cy="39.2582781"
                          rx="5.34831461"
                          ry="5.28476821"
                        />
                        <g
                          id="Oval-2"
                          transform="translate(45.078652, 44.543046)"
                          fill="#2A2A2A"
                          stroke="#FFFFFF"
                        >
                          <ellipse
                            id="Oval"
                            cx="6.30337079"
                            cy="6.22847682"
                            rx="6.30337079"
                            ry="6.22847682"
                          />
                        </g>
                        <ellipse
                          id="Oval"
                          fill="#FFFFFF"
                          cx="51.3820225"
                          cy="50.7715232"
                          rx="3.24719101"
                          ry="3.20860927"
                        />
                        <line
                          x1="60.741573"
                          y1="10.5695364"
                          x2="45.4606742"
                          y2="36.2384106"
                          id="Path-2"
                          stroke="#FFFFFF"
                        />
                        <line
                          x1="41.6404494"
                          y1="38.692053"
                          x2="10.6966292"
                          y2="38.692053"
                          id="Path-3"
                          stroke="#FFFFFF"
                        />
                        <path
                          d="M45.1143518,41.3567797 C45.1143518,41.3567797 46.1133668,42.6265356 48.1113969,45.1660474 C46.1133668,42.6265356 45.1143518,41.3567797 45.1143518,41.3567797 Z"
                          id="Path-4"
                          stroke="#FFFFFF"
                          transform="translate(46.612874, 43.261414) rotate(7.000000) translate(-46.612874, -43.261414) "
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>

              <img
                style={{ marginLeft: '-53px', marginBottom: '18px' }}
                src={mobileTel}
                alt="mobile-icon"
              />
            </a>
          </div>

          <div>
            <Link to={getRoute(lang, '/')}>
              <img
                style={{ marginRight: '-123px' }}
                className="mobile-icon"
                src={mobile}
                alt="mobile-icon"
              />

              <svg
                width="133px"
                height="115px"
                viewBox="0 0 133 115"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Polygon@1x</title>
                <g
                  id="Helmut4"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="00-HelmutTools_Home-START-Mobile"
                    transform="translate(-93.000000, -8034.000000)"
                    stroke="#FFFFFF"
                  >
                    <g
                      id="Group-23"
                      transform="translate(74.000000, 7982.000000)"
                    >
                      <polygon
                        id="Polygon"
                        transform="translate(85.500000, 109.500000) scale(-1, 1) rotate(90.000000) translate(-85.500000, -109.500000) "
                        points="85.5 44 142.224664 76.75 142.224664 142.25 85.5 175 28.7753361 142.25 28.7753361 76.75"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </Link>
          </div>
        </div>
        <hr />
        <div className="footer-menu">
          {footerMenu.map((item, i) => {
            const isHomepageLink = item.url.includes('/homepage/');
            return (
              <Link key={i} to={isHomepageLink ? getRoute(lang, '/') : getRoute(lang, formatUrl(item.url))}>
                {item.title}
              </Link>
            );
          })}
          <div />
          <div className="social-media-links">
            {socialMediaLinks.map(({ link }, i) => (
              <a href={link.url} key={i} target={link.target}>
                {link.title === 'facebook'
                  ? <Facebook /> : link.title === 'instagram'
                    ? <Instagram /> : link.title === 'linkedin'
                      ? <Linkedin /> : null}
              </a>
            ))}
          </div>
          <div />
        </div>
        <hr />
        <div className="footer-elements">
          <p className="copyright">
            {' '}
            {ReactHtmlParser(wordpressAcfOptions.options.copyright_text)}
          </p>
        </div>
      </div>
    </footer>
  );
};
// FooterComponent.propTypes = {
//   footerText: PropTypes.shape({
//     imprint: PropTypes.string,
//     copyright: PropTypes.string,
//     copyrightmobile: PropTypes.string,
//     privacy: PropTypes.string,
//   }).isRequired,
// };
export default FooterComponent;
